import { FC } from 'react';
import IconProps from './IconProps';

const TrainingAwarenessIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.8433 29.5078C28.5233 29.5078 28.2165 29.3807 27.9903 29.1545C27.764 28.9282 27.6369 28.6214 27.6369 28.3015C27.6369 27.9815 27.764 27.6747 27.9903 27.4484C28.2165 27.2222 28.5233 27.0951 28.8433 27.0951C30.1226 27.0936 31.3491 26.5848 32.2537 25.6802C33.1584 24.7755 33.6672 23.549 33.6687 22.2697V7.79352C33.6672 6.51419 33.1584 5.28768 32.2537 4.38306C31.3491 3.47844 30.1226 2.96958 28.8433 2.96812H8.01739C6.73807 2.96958 5.51156 3.47844 4.60694 4.38306C3.70232 5.28768 3.19346 6.51419 3.192 7.79352V20.6799C3.192 20.9999 3.0649 21.3067 2.83866 21.5329C2.61243 21.7592 2.30559 21.8863 1.98565 21.8863C1.6657 21.8863 1.35886 21.7592 1.13263 21.5329C0.906394 21.3067 0.779297 20.9999 0.779297 20.6799V7.79352C0.781392 5.8745 1.54465 4.03468 2.9016 2.67772C4.25855 1.32077 6.09837 0.557515 8.01739 0.55542H28.8433C30.7623 0.557515 32.6021 1.32077 33.9591 2.67772C35.316 4.03468 36.0793 5.8745 36.0814 7.79352V22.2697C36.0793 24.1887 35.316 26.0285 33.9591 27.3855C32.6021 28.7425 30.7623 29.5057 28.8433 29.5078Z"
          fill="#5A5766"
          stroke="#5A5766"
          strokeWidth="0.766667"
        />
        <path
          d="M14.6212 10.0792C13.9054 10.0792 13.2057 10.2915 12.6106 10.6891C12.0154 11.0868 11.5515 11.652 11.2776 12.3133C11.0037 12.9746 10.932 13.7023 11.0717 14.4043C11.2113 15.1063 11.556 15.7512 12.0621 16.2573C12.5683 16.7634 13.2131 17.1081 13.9151 17.2478C14.6172 17.3874 15.3448 17.3157 16.0061 17.0418C16.6674 16.7679 17.2327 16.304 17.6303 15.7089C18.028 15.1137 18.2402 14.414 18.2402 13.6983C18.2391 12.7388 17.8575 11.8189 17.179 11.1404C16.5006 10.4619 15.5807 10.0803 14.6212 10.0792ZM14.6212 7.6665C15.8142 7.6665 16.9803 8.02026 17.9722 8.68304C18.9642 9.34581 19.7373 10.2878 20.1938 11.39C20.6503 12.4922 20.7698 13.7049 20.537 14.875C20.3043 16.045 19.7298 17.1198 18.8863 17.9633C18.0427 18.8069 16.968 19.3814 15.7979 19.6141C14.6279 19.8468 13.4151 19.7274 12.3129 19.2709C11.2108 18.8143 10.2688 18.0412 9.60597 17.0493C8.9432 16.0574 8.58944 14.8912 8.58944 13.6983C8.58944 12.0985 9.22493 10.5643 10.3561 9.43316C11.4873 8.30199 13.0215 7.6665 14.6212 7.6665ZM17.5403 24.5554H11.5721C10.2928 24.5569 9.06624 25.0657 8.16162 25.9703C7.257 26.875 6.74814 28.1015 6.74668 29.3808C6.7474 30.0205 7.00183 30.6337 7.45414 31.086C7.90646 31.5383 8.51972 31.7928 9.15938 31.7935H19.953C20.5927 31.7928 21.206 31.5383 21.6583 31.086C22.1106 30.6337 22.365 30.0205 22.3657 29.3808C22.3643 28.1015 21.8554 26.875 20.9508 25.9703C20.0462 25.0657 18.8197 24.5569 17.5403 24.5554ZM17.5403 22.1427C19.46 22.1427 21.301 22.9053 22.6584 24.2627C24.0158 25.6201 24.7784 27.4611 24.7784 29.3808C24.7784 30.6606 24.27 31.8879 23.3651 32.7929C22.4602 33.6978 21.2328 34.2062 19.953 34.2062H9.15938C7.87961 34.2062 6.65225 33.6978 5.74731 32.7929C4.84237 31.8879 4.33398 30.6606 4.33398 29.3808C4.33398 27.4611 5.09657 25.6201 6.45397 24.2627C7.81138 22.9053 9.65242 22.1427 11.5721 22.1427H17.5403Z"
          fill="url(#paint0_linear_11092_1842)"
          stroke="url(#paint1_linear_11092_1842)"
          strokeWidth="0.766667"
        />
        <defs>
          <linearGradient id="paint0_linear_11092_1842" x1="4.33398" y1="15.7702" x2="26.1488" y2="22.9539" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_11092_1842" x1="4.33398" y1="15.7702" x2="26.1488" y2="22.9539" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9048 19.5048C17.7128 19.5048 17.5287 19.4285 17.393 19.2928C17.2572 19.157 17.181 18.9729 17.181 18.781C17.181 18.589 17.2572 18.4049 17.393 18.2691C17.5287 18.1334 17.7128 18.0571 17.9048 18.0571C18.6724 18.0563 19.4083 17.7509 19.9511 17.2082C20.4938 16.6654 20.7992 15.9295 20.8 15.1619V6.47619C20.7992 5.70859 20.4938 4.97269 19.9511 4.42991C19.4083 3.88714 18.6724 3.58183 17.9048 3.58095H5.40926C4.64167 3.58183 3.90576 3.88714 3.36299 4.42991C2.82022 4.97269 2.5149 5.70859 2.51403 6.47619V14.208C2.51403 14.4 2.43777 14.5841 2.30203 14.7198C2.16629 14.8556 1.98218 14.9318 1.79022 14.9318C1.59825 14.9318 1.41415 14.8556 1.27841 14.7198C1.14266 14.5841 1.06641 14.4 1.06641 14.208V6.47619C1.06766 5.32478 1.52562 4.22088 2.33979 3.40671C3.15396 2.59254 4.25785 2.13459 5.40926 2.13333H17.9048C19.0562 2.13459 20.1601 2.59254 20.9743 3.40671C21.7884 4.22088 22.2464 5.32478 22.2477 6.47619V15.1619C22.2464 16.3133 21.7884 17.4172 20.9743 18.2314C20.1601 19.0455 19.0562 19.5035 17.9048 19.5048Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M9.37252 7.84761C8.94305 7.84761 8.52323 7.97496 8.16614 8.21356C7.80905 8.45216 7.53073 8.7913 7.36638 9.18807C7.20203 9.58485 7.15903 10.0215 7.24281 10.4427C7.3266 10.8639 7.53341 11.2508 7.83709 11.5545C8.14077 11.8582 8.52768 12.065 8.94889 12.1487C9.37011 12.2325 9.80671 12.1895 10.2035 12.0252C10.6003 11.8608 10.9394 11.5825 11.178 11.2254C11.4166 10.8683 11.5439 10.4485 11.5439 10.019C11.5433 9.44334 11.3143 8.89141 10.9072 8.48433C10.5001 8.07725 9.94822 7.84827 9.37252 7.84761ZM9.37252 6.39999C10.0883 6.39999 10.788 6.61225 11.3832 7.00991C11.9783 7.40758 12.4422 7.9728 12.7161 8.63409C12.99 9.29539 13.0617 10.0231 12.922 10.7251C12.7824 11.4271 12.4377 12.072 11.9316 12.5781C11.4254 13.0842 10.7806 13.4289 10.0786 13.5686C9.37653 13.7082 8.64886 13.6365 7.98757 13.3626C7.32627 13.0887 6.76106 12.6248 6.36339 12.0297C5.96572 11.4345 5.75347 10.7348 5.75347 10.019C5.75347 9.05921 6.13476 8.13869 6.81346 7.45999C7.49217 6.78129 8.41269 6.39999 9.37252 6.39999ZM11.124 16.5333H7.54305C6.77546 16.5342 6.03955 16.8395 5.49678 17.3823C4.954 17.9251 4.64869 18.661 4.64781 19.4286C4.64825 19.8124 4.8009 20.1803 5.07229 20.4517C5.34368 20.7231 5.71163 20.8758 6.09543 20.8762H12.5716C12.9554 20.8758 13.3234 20.7231 13.5948 20.4517C13.8662 20.1803 14.0188 19.8124 14.0192 19.4286C14.0184 18.661 13.7131 17.9251 13.1703 17.3823C12.6275 16.8395 11.8916 16.5342 11.124 16.5333ZM11.124 15.0857C12.2758 15.0857 13.3804 15.5433 14.1949 16.3577C15.0093 17.1721 15.4669 18.2768 15.4669 19.4286C15.4669 20.1964 15.1618 20.9328 14.6189 21.4758C14.0759 22.0188 13.3395 22.3238 12.5716 22.3238H6.09543C5.32757 22.3238 4.59115 22.0188 4.04819 21.4758C3.50523 20.9328 3.2002 20.1964 3.2002 19.4286C3.2002 18.2768 3.65775 17.1721 4.47219 16.3577C5.28663 15.5433 6.39126 15.0857 7.54305 15.0857H11.124Z"
        fill={accentColor}
        stroke={accentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default TrainingAwarenessIcon;
